import './App.css';
import ImageSlider from './Components/ImageSlider';
import Header from './Components/Header';
import SplashScreen from './Components/SplashScreen';
import SectionOne from './Components/Sections/One';
import ImageSection from './Components/Sections/ImageSection';
import OurWorks from './Components/Sections/OurWorks';
import Team from './Components/Sections/Team';
import Footer from './Components/Footer';
import { Cloudinary } from "@cloudinary/url-gen";


function App() {
  // Create a Cloudinary instance and set your cloud name.
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'dqaatcysf',
    }
  });

  return (
    <div className="App">
      <SplashScreen />

      <Header />

      <div id="home"></div>
      {/* This is the Hero Image Slider Section  */}
      <ImageSlider
        auto
        images={[
          'Clients/Atelier/appartments/nnlsd8ghg3ugfoc6sn0i',
          'Clients/Atelier/Interior views/lachvgolseufjhq5v0oa',
          'Clients/Atelier/Residential/zcps6cpmk0mucpabrr9s',
          'Clients/Atelier/Residential/gom9aqiapkwgiergoura',
          'Clients/Atelier/Resorts/bfyevodapwiaqvqhewyf',
          'Clients/Atelier/Commercial and hotel project/vvogfjardcmxgncksyuo',
        ]}
        cld={cld}
      />

      <SectionOne id="about" />

      <ImageSection bgImage='https://res.cloudinary.com/dqaatcysf/image/upload/v1710742914/Clients/Atelier/Resorts/x5tyqi1cemfsetfj2rxw.jpg' />

      <div id='projects'></div>
      <OurWorks
        title={<span>Our featured <b>Appartments Works</b></span>}
        cld={cld}
        works={[

          { tags: ["Project", "Appartments", "Atelier"], image: { alt: "Appartments", src: 'Clients/Atelier/appartments/e3jtcnagomt5mznygepr', } },
          { tags: ["Project", "Appartments", "Atelier"], image: { alt: "Appartments", src: 'Clients/Atelier/appartments/nnlsd8ghg3ugfoc6sn0i', } },

        ]}
      />

      <OurWorks
        id={'projects'}
        title={<span>Our featured <b>Interior Works</b></span>}
        cld={cld}
        works={[

          { tags: ["Project", "Interior", "Atelier"], image: { alt: "Interior", src: 'Clients/Atelier/Interior views/qlrsmombgwxioujvdjr3', } },
          { tags: ["Project", "Interior", "Atelier"], image: { alt: "Interior", src: 'Clients/Atelier/Interior views/badod9b8izj4uquxyfd6', } },
          { tags: ["Project", "Interior", "Atelier"], image: { alt: "Interior", src: 'Clients/Atelier/Interior views/qj6ku1usln8m5hpwvmsj', } },
          { tags: ["Project", "Interior", "Atelier"], image: { alt: "Interior", src: 'Clients/Atelier/Interior views/b4foxxeit5csx39stujq', } },
          { tags: ["Project", "Interior", "Atelier"], image: { alt: "Interior", src: 'Clients/Atelier/Interior views/yfz0ufcklmuh07gszhnd', } },
          { tags: ["Project", "Interior", "Atelier"], image: { alt: "Interior", src: 'Clients/Atelier/Interior views/lachvgolseufjhq5v0oa', } },
        ]}
      />

      <OurWorks
        id={"residential"}
        title={<span>Our featured <b>Residential Projects</b></span>}
        cld={cld}
        works={[
          { tags: ["Project", "Residential", "Atelier"], image: { alt: "Residential", src: 'Clients/Atelier/Residential/zcps6cpmk0mucpabrr9s', } },
          { tags: ["Project", "Residential", "Atelier"], image: { alt: "Residential", src: 'Clients/Atelier/Residential/l8mkkpym1jncjmvlw7bx', } },
          { tags: ["Project", "Residential", "Atelier"], image: { alt: "Residential", src: 'Clients/Atelier/Residential/gom9aqiapkwgiergoura', } },
          { tags: ["Project", "Residential", "Atelier"], image: { alt: "Residential", src: 'Clients/Atelier/Residential/deigqd9juaipdiowrmvz', } },
          { tags: ["Project", "Residential", "Atelier"], image: { alt: "Residential", src: 'Clients/Atelier/Residential/y62uintmpt1dgtwred5y', } },
          { tags: ["Project", "Residential", "Atelier"], image: { alt: "Residential", src: 'Clients/Atelier/Residential/qsmsoj7lpavq1yrkfpxb', } },
          { tags: ["Project", "Residential", "Atelier"], image: { alt: "Residential", src: 'Clients/Atelier/Residential/evketkgpvzfkomkp4i8n', } },
          { tags: ["Project", "Residential", "Atelier"], image: { alt: "Residential", src: 'Clients/Atelier/Residential/be6iivhnxgknll9te6pd', } },
          { tags: ["Project", "Residential", "Atelier"], image: { alt: "Residential", src: 'Clients/Atelier/Residential/x4ombvwh5yagqoctay8f', } },
          { tags: ["Project", "Residential", "Atelier"], image: { alt: "Residential", src: 'Clients/Atelier/Residential/cuutqd4xwunsysiyk7zb', } },

        ]}
      />

      <OurWorks
        id={"resort"}
        title={<span>Our featured <b>Resort Works</b></span>}
        cld={cld}
        works={[
          { tags: ["Project", "Resort", "Atelier"], image: { alt: "Resort", src: 'Clients/Atelier/Resorts/x5tyqi1cemfsetfj2rxw', } },
          { tags: ["Project", "Resort", "Atelier"], image: { alt: "Resort", src: 'Clients/Atelier/Resorts/bfyevodapwiaqvqhewyf', } },
          { tags: ["Project", "Resort", "Atelier"], image: { alt: "Resort", src: 'Clients/Atelier/Resorts/crprrdtk9oo6f6kbgmst', } },
          { tags: ["Project", "Resort", "Atelier"], image: { alt: "Resort", src: 'Clients/Atelier/Resorts/yccoxmlir6t8wnrstuom', } },

        ]}
      />

      <OurWorks
        title={<span>Our featured <b>Mosque Works</b></span>}
        cld={cld}
        works={[
          { tags: ["Project", "Mosque", "Atelier"], image: { alt: "Mosque", src: 'Clients/Atelier/Mosque/mzxx2zkjcomjxpsk1vwe', } },
          { tags: ["Project", "Mosque", "Atelier"], image: { alt: "Mosque", src: 'Clients/Atelier/Mosque/mtnq6pyt8tuvr9zkdegb', } },
        ]}
      />

      <ImageSection bgImage='https://res.cloudinary.com/dqaatcysf/image/upload/v1700896932/Clients/Atelier/Interior/ijomcihrkaygffk5flt2.jpg' />


      <OurWorks
        title={<span>Our featured <b>Commercial and hotel project</b></span>}
        cld={cld}
        works={[
          { tags: ["Project", "Commercial", "Hotel", "Atelier"], image: { alt: "Mosque", src: 'Clients/Atelier/Commercial and hotel project/ahnp0sxte6stnflw7cv8', } },
          { tags: ["Project", "Commercial", "Hotel", "Atelier"], image: { alt: "Mosque", src: 'Clients/Atelier/Commercial and hotel project/wfgwjntot4tvtjgwzk8u', } },
          { tags: ["Project", "Commercial", "Hotel", "Atelier"], image: { alt: "Mosque", src: 'Clients/Atelier/Commercial and hotel project/wcxw1jqiizenm5oni2hj', } },
          { tags: ["Project", "Commercial", "Hotel", "Atelier"], image: { alt: "Mosque", src: 'Clients/Atelier/Commercial and hotel project/vvogfjardcmxgncksyuo', } },
          { tags: ["Project", "Commercial", "Hotel", "Atelier"], image: { alt: "Mosque", src: 'Clients/Atelier/Commercial and hotel project/kdkwoajobgbprfxtdgmu', } },

        ]}
      />


      <OurWorks
        id={"villas"}
        title={<span>Our featured <b>Villa Projects</b></span>}
        cld={cld}
        works={[
          { tags: ["Project", "Villa", "Atelier"], image: { alt: "Mosque", src: 'Clients/Atelier/Villa project/oydjxsjhleql8t1h3zv3', } },
          { tags: ["Project", "Villa", "Atelier"], image: { alt: "Mosque", src: 'Clients/Atelier/Villa project/b6a5ad17fefa7x46fwag', } },
          { tags: ["Project", "Villa", "Atelier"], image: { alt: "Mosque", src: 'Clients/Atelier/Villa project/gd8g7jdsienxgfyddhjm', } },
          { tags: ["Project", "Villa", "Atelier"], image: { alt: "Mosque", src: 'Clients/Atelier/Villa project/rkau9ewfubxilxo2pivn', } },
          { tags: ["Project", "Villa", "Atelier"], image: { alt: "Mosque", src: 'Clients/Atelier/Villa project/hxsgkv01rxuwqwmrlzjn', } },
          { tags: ["Project", "Villa", "Atelier"], image: { alt: "Mosque", src: 'Clients/Atelier/Villa project/p2du0kovchuj6e7ub7ib', } },
          { tags: ["Project", "Villa", "Atelier"], image: { alt: "Mosque", src: 'Clients/Atelier/Villa project/qcthnap7tu0a7kqx8jd6', } },
          { tags: ["Project", "Villa", "Atelier"], image: { alt: "Mosque", src: 'Clients/Atelier/Villa project/tuoghqnnffelugjzndv5', } },
          { tags: ["Project", "Villa", "Atelier"], image: { alt: "Mosque", src: 'Clients/Atelier/Villa project/io7jt9rs9wsqlx9qs2rw', } },
          { tags: ["Project", "Villa", "Atelier"], image: { alt: "Mosque", src: 'Clients/Atelier/Villa project/fegvby5yxkdrs6iea2bo', } },
          { tags: ["Project", "Villa", "Atelier"], image: { alt: "Mosque", src: 'Clients/Atelier/Villa project/lj8i6w4ja8ashggldhel', } },
          { tags: ["Project", "Villa", "Atelier"], image: { alt: "Mosque", src: 'Clients/Atelier/Villa project/jybnzv68pjdwzameppjq', } },
          { tags: ["Project", "Villa", "Atelier"], image: { alt: "Mosque", src: 'Clients/Atelier/Villa project/bnzg2q3xw5nsjfd5nrii', } },
          { tags: ["Project", "Villa", "Atelier"], image: { alt: "Mosque", src: 'Clients/Atelier/Villa project/b5vxpxxmvksrnk5ktn6p', } },
          { tags: ["Project", "Villa", "Atelier"], image: { alt: "Mosque", src: 'Clients/Atelier/Villa project/gvnt5qnvrgavnnhdaqur', } },
        ]}
      />

      <Team />

      <Footer />


      <a style={{
        position: "fixed",
        bottom: "80px",
        right: "20px",
        width: "90px",
        height: "90px",
        borderRadius: "50%",
      }}
        href='https://www.instagram.com/atelier_civil_consultant?igsh=MWN1MGk5ZXJjNzZtZQ=='>
        <img
          width={50}
          height={50}
          src='https://img.freepik.com/free-vector/instagram-icon_1057-2227.jpg?w=740&t=st=1712212920~exp=1712213520~hmac=be1922ed6903045a716b57ca21a2d4f45faa803ad3a8b247b1c093fbceb08d1c' />
      </a>



      <a style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        background: "Green",
        border: "solid 20px green"
      }}
        href='https://wa.me/918129970726?text=I%20found%20your%20Atelier%20website.%20I%20want%20to%20talk%20to%20you'
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="50px" viewBox="0 0 448 512" fill='white'>
          <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
        </svg>
      </a>

      <a style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        background: "Green",
        border: "solid 20px green"
      }}
        href='https://wa.me/918129970726?text=I%20found%20your%20Atelier%20website.%20I%20want%20to%20talk%20to%20you'
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="50px" viewBox="0 0 448 512" fill='white'>
          <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
        </svg>
      </a>

    </div>
  );
}

export default App;
