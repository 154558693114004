import { AdvancedImage } from '@cloudinary/react';
import ProcessList from '../ProcessList';
import s from './ourworks.module.css'
import { fill } from "@cloudinary/url-gen/actions/resize";

import SectionTitle from "./Title"


const OurWorks = ({ id, title, works, cld }) => {
    return (
        <section 
        id={id}
        className={s.section}>
            <SectionTitle>
                {title}
            </SectionTitle>
            {
                works
                    .map((work, index) => {

                        if (cld) {
                            const myImage = cld.image(work.image.src)

                            const width = Math.min(Math.floor(window.innerWidth * 0.8), 800)
                            const height = Math.floor(width * 9 / 16)

                            myImage.resize(fill().width(width).height(height));

                            return (
                                <div key={index}>
                                    <ProcessList list={work.tags} />
                                    <AdvancedImage
                                        className={s.image}
                                        cldImg={myImage}
                                    />
                                </div>
                            )
                        }

                        return (
                            <div key={index}>
                                <ProcessList list={work.tags} />
                                <img
                                    className={s.image}
                                    alt={work.image.alt}
                                    src={work.image.src}
                                />
                            </div>
                        )
                    })
            }
        </section>
    )
}

export default OurWorks;