import { useState } from 'react';
import s from './style.module.css'

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
        <header className={s.container}>
            <div className={s.header}>
                <img className={s.logo} src='./images/logo.png' alt='' />
                <div className={`${s.hamburgerMenu} ${showMenu ? s.hide : s.show}`} onClick={toggleMenu}>
                    <div className={s.hamburgerIcon}></div>
                    <div className={s.hamburgerIcon}></div>
                    <div className={s.hamburgerIcon}></div>
                </div>

                <div className={`${s.navItems} ${showMenu ? s.showMenu : ''}`}>
                    {[
                        { name: "Home", link: "#home" },
                        { name: "About Us", link: "#about" },
                        { name: "Projects", link: "#projects" },
                        { name: "Residential", link: "#residential" },
                        { name: "Villas", link: "#villas" },
                    ]
                        .map((item, index) => (
                            <a
                                key={`nav-item-${index}`}
                                className={s.item}
                                href={item.link}
                                onClick={() => setShowMenu(false)} // Close menu on item click
                            >
                                {item.name}
                            </a>
                        ))}
                </div>
            </div>
        </header>
    )
}

export default Header;