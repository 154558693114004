// Import React and useState hook
import React, { useState, useRef, useEffect } from "react";
import s from './styles.module.css'

import { AdvancedImage } from '@cloudinary/react';
import { fill } from "@cloudinary/url-gen/actions/resize";

// Define the ImageSlider component
const ImageSlider = ({
  auto = false,
  interval = 3000,
  images = [
    "https://images.unsplash.com/photo-1501785888041-af3ef285b470",
    "https://images.unsplash.com/photo-1504711434969-e33886168f5c",
    "https://images.unsplash.com/photo-1505118380757-91f5f5632de0",
  ],
  cld = null,
}) => {
  // Define the current index state
  const [current, setCurrent] = useState(0);

  // Define the ref for the image element
  const sliderRef = useRef(null);

  // Define the handler for going to the next image
  const next = () => {
    // Set the current index to the next one
    var nextIndex = (current + 1) % images.length

    sliderRef.current.style.transform = `translateX(-${nextIndex * 100}vw)`;
    setCurrent(nextIndex);
  };


  // Define the handler for going to the previous image
  const prev = () => {
    // Set the current index to the previous one
    var prevIndex = (current - 1 + images.length) % images.length

    sliderRef.current.style.transform = `translateX(-${prevIndex * 100}vw)`;
    setCurrent(prevIndex)
  };

  useEffect(() => {
    if (auto) {
      const handleAutoPlay = setInterval(next, interval)

      return () => clearInterval(handleAutoPlay);
    }
  }, [auto, next])


  // Return the JSX element
  return (
    <div className={s.container}>
      <div className={s.slides}
        ref={sliderRef}
      >
        {images.map((image, index) => {

          // Section to handle the cloudinary images
          /**
           * $cld : refers to the cloudinary instence
           */
          if (cld) {
            const myImage = cld.image(image);
            myImage.resize(fill().width(window.innerWidth).height(window.innerHeight));

            return <div key={index}><AdvancedImage cldImg={myImage} /></div>
          }

          // Section to handle if the public url of the images are given
          return (
            <img
              key={index}
              src={image}
              alt={`slider-${index}`}
            />
          )
        })}
      </div>
      <div className={s.controls}>
        <button onClick={prev}>Prev</button>
        <button onClick={next}>Next</button>
      </div>
    </div>
  );
};

// Export the component
export default ImageSlider;
