import SectionTitle from '../Title';
import s from './team.module.css'

const Team = () => {
    return (
        <section className={s.section}>
            <SectionTitle>
                Our Fantastic <b>Team</b>
            </SectionTitle>
            <section>
                Behind every successful endeavor lies a remarkable team, and ours is nothing short of exceptional. Together, we weave a tapestry of talents, ideas, and relentless dedication that fuels our achievements.

                Our team is more than just a group of individuals; it's a synergy of diverse strengths, a collective brilliance that propels us forward. Each member, with their unique skills and perspectives, contributes to our shared success.
            </section>
            <div className={`${s.row} ${s.movingborder}`}>
                {[1, 2, 3].map(i => {
                    return (
                        <div className={s.imageContainer}>
                            <img
                                src='https://img.freepik.com/free-photo/fashion-boy-with-yellow-jacket-blue-pants_71767-96.jpg?w=740&t=st=1700976292~exp=1700976892~hmac=b960e6089ff53485f350ef50e0dffcb0f29736f628730f6813d04ecf2ee94308'
                                alt='' />
                        </div>
                    )
                })}
            </div>
        </section>
    )
}

export default Team;