import s from './footer.module.css'

const Footer = () => {
    return (
        <footer className={s.footer}>
            <p>
                First Floor, Horizon Tower Bypass
                Junction, Kainatty, Kerala 673122
            </p>
            <p>
                Contact:8129970726, 9961178527
            </p>
            <p>
                atelierkalpetta@gmail.com
            </p>
            <p>All rights reserved. Created by Cup-N-Lap</p>
        </footer>
    )
}

export default Footer;